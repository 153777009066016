import {Language} from 'src/components/widget/types/language';

export const en: Language = {
	frequency: 'Frequency',
	monthly: 'Monthly',
	monthlyDonation: 'Monthly',
	oneTimeDonation: 'One-time',
	amount: 'Amount',
	amountError: 'Enter an amount to donate',
	frequencySelect: 'Select frequency',
	frequencyPopover:
		'Monthly gifts help nonprofits focus on their mission and long-term impact',
	currencyPopover:
		'It’s recommended to donate {{suggestedCurrency}} if you are getting a {{country}} tax receipt, would you like to switch from <bold>{{fromCurrency}} to {{toCurrency}}</bold>?',
	switchCurrency: 'Change to {{suggestedCurrency}}',
	noThanks: 'No thanks',
	orDonateCrypto: 'or donate with:',
	countryTitle: 'Tax deductible in',
	countrySelection: 'Select your preferred country',
	donate: 'Donate',
	countryDescription:
		'Support {{projectName}} with a donation to {{nameAndRegistration}}.',
	simpleRedirectNotice: 'Every.org will process your donation.',
	learnMore: 'Learn More',
	donationRedirectNotice:
		'You will be redirected to Every.org to complete your {{currency}} donation to {{nameAndRegistration}}.',
	thanksDonation: ``,
	minDonationAmount: 'The minimum donation amount is',
	chooseAnAmount: 'Choose an amount',
	enterAnAmount: 'Enter an amount',
	change: 'Change',
	tax: 'Tax deductible in:'
};
