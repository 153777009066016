export enum Spacing {
	Empty = '0',

	XXS = '4px',
	XS = '8px',
	S = '12px',
	M = '16px',
	L = '20px',
	XL = '24px',
	XXL = '32px',

	Stack_XXS = '0 0 4px 0',
	Stack_XS = '0 0 8px 0',
	Stack_S = '0 0 12px 0',
	Stack_M = '0 0 16px 0',
	Stack_L = '0 0 20px 0',
	Stack_XL = '0 0 24px 0',
	Stack_XXL = '0 0 32px 0',

	Inset_XXS = '4px 4px 4px 4px',
	Inset_XS = '8px 8px 8px 8px',
	Inset_S = '12px 12px 12px 12px',
	Inset_M = '16px 16px 16px 16px',
	Inset_L = '20px 20px 20px 20px',
	Inset_XL = '24px 24px 24px 24px',
	Inset_XXL = '32px 32px 32px 32px',

	InsetSquish_XS = '8px 16px',
	InsetSquish_S = '12px 24px',
	InsetSquish_M = '16px 32px',
	InsetSquish_L = '20px 40px',
	InsetSquish_XL = '24px 48px',

	Inline_XS = '0 8px 0 0',
	Inline_S = '0 12px 0 0',
	Inline_M = '0 16px 0 0',
	Inline_L = '0 20px 0 0',
	Inline_XL = '0 24px 0 0',
	Inline_XXL = '0 24px 0 0'
}
